import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Icon from '../components/icons';
import { Link } from 'gatsby';

export default ({ location: { pathname }}) => (
    <Layout>
        <SEO
            title="Tack! | Jämför snuspriser online"
            keywords={['kontakt', 'billigt', 'snus', 'billigprilla.se']}
            pathname={pathname}
        />
            <h1 className="text-4xl flex items-center mb-2">
                <Icon.Checkmark className="border border-primary rounded-full w-10 h-10 mr-4" />
                Tack!
            </h1>
            <p className="mb-4">
                Jag läser och svarar så fort jag kan!
            </p>
            <Link to="/" className="link">
                Tillbaka
            </Link>
    </Layout>
);
